<template>
  <a-layout class='index animated fadeIn'>
    <a-card class="mb10">
      <a-button size='small' @click="()=>{$router.go(-1)}">
        <LeftOutlined />返回
      </a-button>
      <span class="ml15 card_title" v-if='$route.query.type == "look"'>品牌商详情</span>
      <span class="ml15 card_title" v-else>{{$route.query.type == 'add' ? '添加':'修改'}}品牌商</span>
    </a-card>
    <a-card>
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="company" label="公司名称" name="company">
          <a-input v-model:value="form.company" placeholder="请输入公司名称" :readonly='$route.query.type =="look"' />
        </a-form-item>
        <a-form-item ref="contact_name" label="联系人" name="contact_name">
          <a-input v-model:value="form.contact_name" placeholder="请输入品牌商联系人名称" :readonly='$route.query.type =="look"' />
        </a-form-item>
        <a-form-item ref="contact_phone" label="手机号" name="contact_phone">
          <a-input v-model:value="form.contact_phone" placeholder="请输入品牌商联系人的手机号" :readonly='$route.query.type =="look"' />
        </a-form-item>
        <a-form-item ref="brand_no" label="账号" name="account">
          <a-input v-model:value="form.account" placeholder="请输入账号（账号必须为手机号码）" :readonly='$route.query.type =="look" || $route.query.type =="edit"' />
        </a-form-item>
        <a-form-item label="是否修改密码" v-if='$route.query.type =="edit"'>
          <a-radio-group v-model:value="is_edit" :disabled='$route.query.type == "add"'>
            <a-radio value="1">
              否
            </a-radio>
            <a-radio value="2">
              是
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item ref="password" label="密码" name="password" help='(6-18位)' v-if='$route.query.type =="add"||($route.query.type !=="look"&&is_edit==2)'>
          <a-input v-model:value="form.password" placeholder="请输入密码" />
        </a-form-item>
        <a-form-item ref="brand_name" label="品牌名称" name="brand_name" :rules="[{ required: true, message: '请输入品牌商名称' }]">
          <a-input v-model:value="form.brand_name" placeholder="请输入品牌名称" :readonly='$route.query.type =="look"' />
        </a-form-item>
        <a-form-item ref="address" label="详细地址" name="address">
          <div class="s_flex_bian_c s_wid100 mb15">
            <a-cascader :disabled='$route.query.type == "look"' v-model:value="region" :field-names="{ label: 'name', value: 'id', children: 'children' }" :options="cityList" placeholder="请选择地址" @change="onChangeAddress" />
          </div>
          <a-input v-model:value="form.address" placeholder='请输入详细地址' :readonly='$route.query.type == "look"' />
        </a-form-item>
      <a-form-item  label="品牌商标题LOGO" name="license_images" help='图片大小不超过2M，支持jpg/png格式(最多上传两张)' >
              <a-upload  name="file"  :showUploadList="false" accept='image/jpeg,image/png,image/jpg' list-type="picture-card" class="avatar-uploader" :action="baseUrl+'upload/alone'" :headers='header' @change="(e)=>ImagesChange(e,'brand_mark')" @preview="handlePreview" :beforeUpload="beforeUpload" :disabled='$route.query.type == "look"'>
               <img  v-if="form.brand_mark" :src="oss+form.brand_mark"  alt="avatar"   style="max-width:102px"/>
               <div  v-if='!form.brand_mark'>
                  <plus-outlined />
                  <div class="ant-upload-text">请上传品牌商标题LOGO</div>
                </div>
              </a-upload>
              <div class="ant-form-explain">尺寸推荐:602*240</div>
            </a-form-item>
            <a-form-item   label="品牌商LOGO" name="license_images" help='图片大小不超过2M，支持jpg/png格式(最多上传两张)' >
              <a-upload  name="file" :showUploadList="false" accept='image/jpeg,image/png,image/jpg' list-type="picture-card" class="avatar-uploader" :action="baseUrl+'upload/alone'" :headers='header' @change="(e)=>ImagesChange(e,'brand_logo')" @preview="handlePreview" :beforeUpload="beforeUpload" :disabled='$route.query.type == "look"'>
                <img v-if="form.brand_logo" :src="oss+form.brand_logo" alt="avatar" style="max-width:102px" />
                <div v-if='!form.brand_logo'>
                  <plus-outlined />
                  <div class="ant-upload-text">请上传品牌商LOGO</div>
                </div>
              </a-upload>
              <div class="ant-form-explain">尺寸推荐:88*88</div>
            </a-form-item>
        <a-form-item label="小程序前缀">
          <a-input v-model:value="form.wxapp_prefix" :readonly='$route.query.type == "look"'>
            <template #addonBefore>
              <a-select v-model:value="wxValueL" style="width: 90px">
                <a-select-option value="http://">
                  http://
                </a-select-option>
                <a-select-option value="https://">
                  https://
                </a-select-option>
              </a-select>
            </template>
          </a-input>
        </a-form-item>
        <a-form-item ref="industry" label="所属行业" name="industry">
          <a-select v-model:value="form.industry" placeholder="请选择所属行业" :disabled='$route.query.type == "look"'>
            <a-select-option v-for='item in industrysList' :key="item" :value="item">
              {{item}}
            </a-select-option>
          </a-select>
        </a-form-item>
        <a-form-item label="E-Mail">
          <a-input v-model:value="form.email" placeholder='请输入邮箱' :readonly='$route.query.type == "look"' />
        </a-form-item>
        <a-form-item required label="应用收费模式" >
           <a-radio-group v-model:value="form.app_is_super" >
           <a-radio :value="0">
              自选模块
            </a-radio>
            <a-radio :value="1">
              全包平台
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item required label="功能模块" name="" v-if='$route.query.type =="add"'>
          <a-radio-group v-model:value="form.app_fees_type" @change='resetFee'>
            <a-radio value="2">
              按服务费收费
            </a-radio>
            <a-radio value="1">
              按年限收费
            </a-radio>
          </a-radio-group>
          <div v-if="form.app_fees_type==2">
            <a-form-item label="选择模块" class="m-b-10">
              <a-select style="width: 200px" @change="handleChange" mode="multiple" v-model:value="form.apps">
                <a-select-option v-for='item in appList' :key='item.id'>
                  {{item.app_name}}
                </a-select-option>
              </a-select>
            </a-form-item>
          </div>
          <div v-if="form.app_fees_type==1">
              <a-checkbox-group v-model:value="form.apps" @change='selectChange'>
                <div class="p-t-10" v-for='(item,index) in appList' :key='item.id'>
                  <a-checkbox :value="item.app_id"  style="margin-bottom:5px">
                    {{item.app_name}}
                      <a-select v-model:value="item.appYears" placeholder="请选择年限" style='width:200px;margin-left:10px' @change='(e)=>selectYearChange(e,index)' v-if="item.yearsList">
                        <a-select-option v-for='item in item.yearsList' :key="item.id" :value="item.id">
                          {{item.valid_year}}年
                        </a-select-option>
                      </a-select>
                    <span v-if='item.discount_price'>（本项合计：{{item.discount_price}}元）</span>
                  </a-checkbox>
                </div>
              </a-checkbox-group>
          </div>
          <a-form-item label="">
            <p v-if='allPrice'>本次需扣除：{{allPrice}}元</p>
            <div class="ant-form-explain">（按模块数量单独收费）
              <a-tooltip placement="rightTop">
                <template #title>
                  <span></span>
                  <p>开通新模块计费方式。例:<br>未使用：模块A（1000元）/1年（365天—65天=300天）<br>开通模块B：(1500元)/365x模块A1年（300天，未使用）=模块B计费</p>
                </template>
                <QuestionCircleOutlined />
              </a-tooltip>
            </div>
          </a-form-item>
        </a-form-item>
         <a-form-item required label="是否允许使用海外地址" name="" >
          <a-radio-group v-model:value="form.is_open_gw">
            <a-radio :value="1">
              允许
            </a-radio>
            <a-radio :value="0">
              不允许
            </a-radio>
          </a-radio-group>
        </a-form-item>
          <a-form-item required label="产线" name="" >
          <a-radio-group v-model:value="form.is_production_line">
            <a-radio :value="1">
              是
            </a-radio>
            <a-radio :value="0">
              否
            </a-radio>
          </a-radio-group>
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 14, offset: 3 }" v-if='$route.query.type !=="look"'>
          <a-button type="primary" @click="onSubmit($route.query.type)">
            {{$route.query.type =="add"?'开户':'修改'}}
          </a-button>
        </a-form-item>
        <a-modal v-model:visible="visible" centered title="请输入密码" @ok="onPayment" @cancel='form.pay_password=""'>
          <a-input-password v-model:value="form.pay_password" placeholder="请输入支付密码" />
        </a-modal>
      </a-form>
    </a-card>
  </a-layout>
</template>

<script>
import { LeftOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import { ref, onMounted, reactive, toRefs, inject } from "vue";
import {
  getBrandtMore,
  brand_create,
  brand_update,
  getBrandIndustrys,
  brandAppYears,
  getBrandAppByYear,
} from "@/api/brand";
import { getArea } from "@/api/public";
import { agentData } from "@/api/system";
import { message } from "ant-design-vue";
import { $iscode } from "@/utils/app";
import { getappServeFees } from "@/api/product";
import config from "@/config";
import { getToken } from "@/libs/util";
const baseUrl = config.baseUrl[process.env.VUE_APP_type];
const oss = config.oss[process.env.VUE_APP_type]
const header = { apiAuth: getToken() };
// 初始化默认筛选项数值
let defForm = {
  company: "",
  brand_name: "",
  account: "",
  password: "123456",
  province: undefined,
  city: undefined,
  region: undefined,
  address: "",
  industry: undefined,
  email: "",
  app_id: [],
  app_fees_type: "1",
  account_fee: 0,
  wxapp_prefix: "",
  apps: [],
  resource: "1",
  pay_password: "",
    brand_logo:'',
  brand_mark:'',
  app_is_super :'',
  is_open_gw:0
};
export default {
  components: { LeftOutlined, QuestionCircleOutlined },
  setup() {
    const form = ref({ ...defForm });
    const state = reactive({
      header,
      visible: false,
      agentData: {},
      allPrice: 0,
      surplusPrice: 0,
      urlValueL: "http://",
      wxValueL: "http://",
      brand_logo:[],
      brand_mark:[],
      address: [],
      region: [],
      industrysList: [],
      modularList: [],
      licenseImages:[],
      cityList: [],
      moreData: {},
      labelCol: { span: 3 },
      wrapperCol: { span: 12 },
      app_idArr: [],
      is_edit: "1",
      appByList: [],
      appYears: "",
      yearsList: [],
      serveSelect: [],
      appList: [],
      rules: {
        company: [
          { required: true, message: "请输入公司名称", trigger: "blur" },
          {
            min: 6,
            max: 20,
            message: "公司名称长度在6到20个字符之间",
            trigger: "blur",
          },
        ],
        contact_name: [
          {
            required: true,
            message: "请输入品牌商联系人名称",
            trigger: "blur",
          },
          {
            min: 2,
            max: 10,
            message: "代理商联系人名称长度在2到10个字符之间",
            trigger: "blur",
          },
        ],
        contact_phone: [
          {
            required: true,
            message: "请输入品牌商联系人手机号",
            trigger: "blur",
          },
          { len: 11, message: "品牌商联系人手机号长度有误", trigger: "blur" },
        ],
         account: [
          { required: true, message: "请输入账号", trigger: "blur" },
        ],
      },
    });
    const ruleForm = ref();
    const $route = inject("$route");
    const $Modal = inject("$Modal");
    const $router = inject("$router");
    const _lodash = inject("_lodash");
    //获取详情
    const getMore = async (id) => {
      const hide = message.loading("正在加载中...", 0);
      let res = await getBrandtMore(id).then((res) => res.data).catch(error => error);
      if ($iscode(res)) {
        res.data.wxapp_prefix = _lodash.split(res.data.wxapp_prefix, "//")[1];
        form.value = res.data;
        state.region = [res.data.province, res.data.city, res.data.region];
        state.brand_logo=[res.data.brand_logo],
        state.brand_mark=[res.data.brand_mark],
        setTimeout(hide);
      } else {
        message.error(res.msg);
      }
    };
    //获取服务模块
    const getappServe = async () => {
      let result = await getappServeFees().then((res) => res.data).catch(error => error);
      state.appList = result.data.data;
    };

    //获取省市区
    const getAreaList = async () => {
      let result = await getArea().then((res) => res).catch(error => error);
      state.cityList = result.data;
    };
    //选择省市区
    const onChangeAddress = (e) => {
      form.value.province = e[0];
      form.value.city = e[1];
      form.value.region = e[2];
    };
    //获取代理商行业
    const getIndustrys = async () => {
      let res = await getBrandIndustrys().then((res) => res.data).catch(error => error);
      if ($iscode(res)) {
        state.industrysList = res.data;
      } else {
        message.error(res.msg);
      }
    };
    //获取可用年限的功能模块
    const getModular = async (app_id) => {
      let res = await getBrandAppByYear({ app_id}).then(
        (res) => res.data
      ).catch(error => error);
      if ($iscode(res)) {
        for (var i in state.appList) {
          if( state.appList[i].app_id==app_id){
            state.appList[i].yearsList=res.data
            state.appList[i].appYears=state.appList[i].appYears?state.appList[i].appYears:''
          }
        }
        state.allPrice = 0;
      } else {
        message.error(res.msg);
      }
    };
    const resetFee = () => {
      state.allPrice = 0;
      state.surplusPrice = 0;
      form.value.apps = [];
      if (state.form.app_fees_type == 1) {
        countPrice();
      } else {
        servePrice();
      }
    };
    // 选择功能模块年限
      const selectYearChange = (e,index) => {
        state.appList[index].appYears=e
         state.appList[index].discount_price = _lodash.round(Number(state.appList[index].sys_price),2);
    };
    //选择功能模块
    const selectChange = (e) => {
      for (var i in state.appList) {
        for (var s in e) {
          if (state.appList[i].app_id == e[s]) {
            getModular(e[s])
            // state.appList[i].discount_price = _lodash.round(Number(state.appList[i].sys_price),2);
          }
        }
      }
      // countPrice();
    };
    const handleChange = () => {
      servePrice();
    };
    const servePrice = () => {
      var price = 0;
      for (var i in state.appList) {
        for (var s in form.value.apps) {
          if (state.appList[i].id == form.value.apps[s]) {
            price += Number(state.appList[i].serve_price);
          }
        }
      }
      console.log(price);
      state.allPrice = price.toFixed(2);
    };
    //计算价格
    const countPrice = () => {
      var price = 0;
      for (var i in state.appByList) {
        for (var s in form.value.apps) {
          if (state.appByList[i].id == form.value.apps[s]) {
            price += state.appByList[i].sys_price;
          }
        }
      }
      state.allPrice = price.toFixed(2);
      state.surplusPrice = _lodash
        .subtract(Number(state.agentData.balance), price)
        .toFixed(2);
    };
    //提交
    const onSubmit = (type) => {
      ruleForm.value
        .validate()
        .then(() => {
          if (form.value.apps.length == 0 && $route.query.type == "add") {
            message.error("请选择功能模块！");
            return false;
          }
          onPayment();
        })
        .catch((error) => {
          console.log(error);
          message.error("请完善表单");
        });
    };
    const onPayment = async () => {
      let res;
      const hide = message.loading("正在加载中...", 0);
      var app_ref_ids=form.value.apps
       if(form.value.app_fees_type==1){
         app_ref_ids=[]
            for (var i in state.appList) {
              for (var s in form.value.apps) {
                if (state.appList[i].app_id == form.value.apps[s]) {
                    app_ref_ids.push(state.appList[i].appYears)
                }
              }
            }
        }
        console.log(app_ref_ids)
      if ($route.query.type == "add") {
 
        res = await brand_create({
          ...form.value,
          wxapp_prefix: state.wxValueL + form.value.wxapp_prefix,
          app_id: state.app_idArr,
          app_ref_ids: app_ref_ids,
        }).then((res) => res.data).catch(error => error);
      } else {
        res = await brand_update({
          ...form.value,
          wxapp_prefix: state.wxValueL + form.value.wxapp_prefix,
          app_id: state.app_idArr,
          app_ref_ids: app_ref_ids,
        }).then((res) => res.data).catch(error => error);
      }
      setTimeout(hide);
      if ($iscode(res, true)) {
        setTimeout(() => {
          $router.go(-1);
        }, 1000);
      }
    };
    onMounted(() => {
      getAreaList();
      getIndustrys();
      getappServe();
      if ($route.query.type == "edit" || $route.query.type == "look") {
        getMore($route.query.id);
      } else {
        let rulesObj = {
          password: [
            { required: true, message: "请输入密码", trigger: "blur" },
            {
              min: 6,
              max: 20,
              message: "密码长度在6到20个字符之间",
              trigger: "blur",
            },
          ],
        };
        state.rules = _lodash.merge(state.rules, rulesObj);
      }
    });
        // 上传资质认证 前
    const beforeUpload = (file, fileList) => {
      if (fileList.length > 2) {
        message.error("最多上传二张图");
        return false;
      }
    };
    // 上传 图片
    const ImagesChange = (info, type) => {
      if (info.file.status === "done") {
        if (type == "license_images") {
        state.licenseImages = info.fileList;
        console.log()
          form.value[type].push(info.file.response.data.path);
        } else {
          state[type]=info.fileList;
         
          form.value[type] = info.file.response.data.path;
           console.log(form.value[type])
        }
      }
      if (info.file.status === "error") {
        message.error("图片上传失败！");
      }
      if (info.file.status === "removed") {
        form.value.license_images = "";
      }
    };
    const getBase64 = (file) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    };
    const handlePreview = async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      state.previewImage = file.url || file.preview;
      state.previewVisible = true;
    };
 
    return {
      form,
      ruleForm,
      handlePreview,
      ImagesChange,
      ...toRefs(state),
      onChangeAddress,
      onSubmit,
      getModular,
      selectChange,
      countPrice,
      onPayment,
      resetFee,
      selectYearChange,
      handleChange,
      baseUrl,
      oss,
      beforeUpload
    };
  },
};
</script>
<style>
</style>